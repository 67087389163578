import { FeedEventType as PrismaFeedEventType } from '@prisma/client';
import { UnreachableCaseError } from 'constants/errors';
import { FeedEventType as GqlFeedEventType } from 'graphql/generated';

export const convertFeedEventTypeGqlToPrisma = (type: GqlFeedEventType): PrismaFeedEventType => {
  switch (type) {
    case GqlFeedEventType.ListCreated:
      return PrismaFeedEventType.LIST_CREATED;
    case GqlFeedEventType.AcceptedInvite:
      return PrismaFeedEventType.ACCEPTED_INVITE;
    case GqlFeedEventType.ListCompleted:
      return PrismaFeedEventType.LIST_COMPLETED;
    case GqlFeedEventType.UserFollowed:
      return PrismaFeedEventType.USER_FOLLOWED;
    default:
      throw new UnreachableCaseError(
        `Gql FeedEventType ${type} in convertFeedEventTypeGqlToPrisma()`,
      );
  }
};

export const convertFeedEventTypePrismaToGql = (type: PrismaFeedEventType): GqlFeedEventType => {
  switch (type) {
    case PrismaFeedEventType.LIST_CREATED:
      return GqlFeedEventType.ListCreated;
    case PrismaFeedEventType.ACCEPTED_INVITE:
      return GqlFeedEventType.AcceptedInvite;
    case PrismaFeedEventType.LIST_COMPLETED:
      return GqlFeedEventType.ListCompleted;
    case PrismaFeedEventType.USER_FOLLOWED:
      return GqlFeedEventType.UserFollowed;
    default:
      throw new UnreachableCaseError(
        `Prisma FeedEventType ${type} in convertFeedEventTypePrismaToGql()`,
      );
  }
};

export const GET_FEED_ITEMS_QUERY_POLLING_INTERVAL_MS = 10 * 1000; // every 10 seconds

export const TRUNCATE_FEED_ITEM_DESCRIPTION_LENGTH = 160;
