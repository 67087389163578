import styled from 'styled-components';
import { theme } from 'utils/theme';
import Body from '../body';

interface DivProps {
  fullWidth?: boolean;
  margin?: string;
}

const Div = styled.div<DivProps>`
  background-color: ${theme.colors.gray200};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};

  margin: ${(props) => props.margin || '10px 0'};

  border-radius: 10px;
  padding: 8px;
`;

type InfoBoxProps = {
  info: string | React.ReactNode;
} & DivProps;

const InfoBox = ({ info, ...options }: InfoBoxProps) => (
  <Div {...options}>
    <Body color={theme.colors.black} leftAlign>
      💡&nbsp;{info}
    </Body>
  </Div>
);

export default InfoBox;
