import gql from 'graphql-tag';
import { USER_CORE_FRAGMENT } from 'utils/fragments.query';

export const LIKE_OR_DISLIKE_IDEALIST = gql`
  mutation LikeOrDislikeIdealist($id: ID!, $like: Boolean!) {
    likeOrDislikeIdealist(id: $id, like: $like) {
      listId
      likeCount
      likedByUser
    }
  }
`;

export const COMPLETE_IDEALIST = gql`
  mutation CompleteIdealist($id: ID!) {
    completedIdealist(id: $id) {
      listId
      completedCount
      user {
        name
        ...UserCore
      }
    }
  }

  ${USER_CORE_FRAGMENT}
`;
