import truncate from 'lodash/truncate';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { BlogImage } from 'components/blog';
import Title from 'components/blog/title';
import Body from 'components/common/body';
import Link from 'components/common/link';
import FadeIn from 'components/common/transitions/FadeIn';
import { BlogPostContentType } from 'constants/blog';
import { TRUNCATE_FEED_ITEM_DESCRIPTION_LENGTH } from 'constants/feedItem';
import { IDEALIST_PLACEHOLDER_IMAGE } from 'constants/images';
import { IDEALIST_URL } from 'constants/urls';
import { Idealist, User } from 'graphql/generated';
import { useAuthenticatedUser } from 'utils/auth';
import { mq } from 'utils/responsive';
import { theme } from 'utils/theme';
import IdealistActions from '../idealistActions';
import IdealistCompletionStats from '../idealistCompletionStats';
import IdealistDetails from '../idealistDetails';
import { IdealistStats } from '../types';

const IdealistFeedItemLi = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${theme.colors.gray300};
  border-radius: 10px;
  padding: 8px;

  background-color: ${theme.colors.white};

  &: hover {
    box-shadow: 0px 0px 12px 1px ${theme.colors.gray100};
    background-color: ${theme.colors.gray100};
  }

  &: not(: last-child) {
    margin-bottom: 50px;
  }
`;

const InnerDiv = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

const PreviewImageDiv = styled.div`
  height: 200px;
  max-height: 200px;
  ${mq.ltsm`
    height: 120px;
    max-height: 120px;
  `}

  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

// TODO: make the Subtitle/Title responsive instead, so we have the logic in one place
// This logic also exists on the metadata of a post.
const ResponsiveDetailsBox = styled.div`
  padding: 0 40px;

  ${mq.ltsm`
    padding: 0 10px;
  `}
`;

type IdealistFeedItemProps = {
  idealist: Pick<
    Idealist,
    'id' | 'title' | 'description' | 'image' | 'createdAt' | 'isDraft' | 'location'
  > & {
    creator: Pick<User, 'id' | 'username' | 'image'>;
    completedBy: Pick<User, 'id' | 'name' | 'image'>[];
  };
} & {
  header?: React.ReactNode;
  idealistStats: IdealistStats;
  skipDescription?: boolean;
  skipFadeIn?: boolean;
  skipLink?: boolean;
  hideActions?: boolean;
  hideCompletionStats?: boolean;
  actionsDoNothing?: boolean; // useful for rendering realistic items on the front page, without making any backend changes
};

const IdealistFeedItem = ({
  header,
  idealist,
  skipDescription,
  skipFadeIn,
  skipLink,
  hideActions,
  hideCompletionStats,
  idealistStats,
  actionsDoNothing,
}: IdealistFeedItemProps) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const user = useAuthenticatedUser();

  const { id: idealistId, title, description, creator, image, completedBy } = idealist;
  const isCreatedByCurrentUser = user?.id === creator?.id;

  const innerPart = (
    <InnerDiv>
      <PreviewImageDiv>
        {/* TODO: make this image centered */}
        <BlogImage
          alt={title}
          type={BlogPostContentType.NORMAL_IMAGE}
          src={image || IDEALIST_PLACEHOLDER_IMAGE}
          center
        />
      </PreviewImageDiv>
      <ResponsiveDetailsBox>
        <Title margin="15px 0">{title}</Title>

        <IdealistDetails idealist={idealist} />

        {!skipDescription && (
          <Body color={theme.colors.black} margin="15px 0 15px 0">
            {truncate(description, { length: TRUNCATE_FEED_ITEM_DESCRIPTION_LENGTH })}
          </Body>
        )}

        {!hideCompletionStats && <IdealistCompletionStats completedBy={completedBy} />}

        {hideActions ? null : (
          <IdealistActions
            idealist={idealist}
            idealistStats={idealistStats}
            actionsDoNothing={actionsDoNothing}
            isCreatedByCurrentUser={isCreatedByCurrentUser}
          />
        )}
      </ResponsiveDetailsBox>
    </InnerDiv>
  );

  return (
    <FadeIn inView={inView} skipFadeIn={skipFadeIn}>
      <IdealistFeedItemLi ref={ref} key={idealist.id}>
        {skipLink ? (
          [header, innerPart]
        ) : (
          <Link
            as={IDEALIST_URL(idealistId)}
            href={IDEALIST_URL(idealistId)}
            key={idealistId}
            stretchParent
          >
            {header}
            {innerPart}
          </Link>
        )}
      </IdealistFeedItemLi>
    </FadeIn>
  );
};

export default IdealistFeedItem;
