import { useMutation } from '@apollo/client';
import React from 'react';
import { ThumbsUp } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import InfoBox from 'components/common/infoBox';
import ModalInfoContainer from 'components/common/modal/modalInfoContainer';
import useModal, { Modal } from 'components/common/modal/useModal';
import {
  CompleteIdealistMutation,
  CompleteIdealistMutationVariables,
  Idealist,
  LikeOrDislikeIdealistMutation,
  LikeOrDislikeIdealistMutationVariables,
  User,
} from 'graphql/generated';
import { FEED_IDEALIST_WITH_USER_STATS_FRAGMENT } from 'utils/fragments.query';
import { theme } from 'utils/theme';
import { IdealistStats } from '../types';
import { COMPLETE_IDEALIST, LIKE_OR_DISLIKE_IDEALIST } from './idealistActions.query';

const ActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

type IdealistActionsProps = {
  idealist: Pick<Idealist, 'id'>;
} & {
  actionsDoNothing?: boolean; // useful for rendering realistic items on the front page, without making any backend changes
  isCreatedByCurrentUser?: boolean;
  idealistStats: IdealistStats;
};

const IdealistActions = ({
  idealist,
  isCreatedByCurrentUser,
  actionsDoNothing,
  idealistStats,
}: IdealistActionsProps) => {
  const { id: idealistId } = idealist;
  const planIdealistFeatureModal = useModal();

  const [likeOrDislikeIdealist, { loading: likeOrDislikeIdealistLoading }] = useMutation<
    LikeOrDislikeIdealistMutation,
    LikeOrDislikeIdealistMutationVariables
  >(LIKE_OR_DISLIKE_IDEALIST, {
    optimisticResponse: {
      likeOrDislikeIdealist: {
        __typename: 'LikeOrDislikeIdealistResponse',
        listId: idealist.id,
        likeCount: idealistStats.likeCount + (idealistStats.likedByUser ? -1 : 1), // dislike if already liked by user
        likedByUser: !idealistStats.likedByUser,
      },
    },
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const { listId, likeCount, likedByUser } = data.likeOrDislikeIdealist;

      cache.updateFragment(
        {
          id: cache.identify({
            // eslint-disable-next-line no-underscore-dangle
            __typename: 'IdealistWithUserStats',
            idealist: { id: listId },
          }),
          fragmentName: 'FeedIdealistWithUserStats',
          fragment: FEED_IDEALIST_WITH_USER_STATS_FRAGMENT,
        },
        (cachedList) => ({ ...cachedList, likeCount, likedByUser }),
      );
    },
  });

  const [completeIdealist, { loading: completeIdealistLoading }] = useMutation<
    CompleteIdealistMutation,
    CompleteIdealistMutationVariables
  >(COMPLETE_IDEALIST, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      const { listId, user: completedByUser } = data.completedIdealist;

      cache.updateFragment(
        {
          id: cache.identify({
            // eslint-disable-next-line no-underscore-dangle
            __typename: 'IdealistWithUserStats',
            idealist: { id: listId },
          }),
          fragmentName: 'FeedIdealistWithUserStats',
          fragment: FEED_IDEALIST_WITH_USER_STATS_FRAGMENT,
        },
        (cachedList) => ({
          ...cachedList,
          completedByUser: true,
          idealist: {
            ...cachedList.idealist,
            completedBy: [
              completedByUser,
              ...cachedList.idealist.completedBy.filter(
                ({ id }: Pick<User, 'id'>) => id !== completedByUser.id,
              ),
            ],
          },
        }),
      );
    },
  });

  return (
    <ActionsDiv
      onClick={(e) => {
        e.preventDefault(); // so the link of the idealist is not triggered
      }}
    >
      <SimpleButton
        disabled={likeOrDislikeIdealistLoading || isCreatedByCurrentUser}
        margin="0 5px 10px 0"
        onClick={async () => {
          if (actionsDoNothing) {
            return;
          }
          likeOrDislikeIdealist({
            variables: { id: idealistId, like: !idealistStats.likedByUser }, // if user likes this, dislike; else, like
          });
        }}
      >
        <Body size="small">{idealistStats.likeCount}&nbsp;</Body>
        {isCreatedByCurrentUser ? (
          <Body size="small">{idealistStats.likeCount === 1 ? 'like' : 'likes'}</Body>
        ) : (
          <ThumbsUp
            color={theme.colors.black}
            fill={idealistStats.likedByUser ? theme.customColors.primary : theme.colors.white}
          />
        )}
      </SimpleButton>
      {!isCreatedByCurrentUser && (
        <>
          <Modal {...planIdealistFeatureModal} ref={planIdealistFeatureModal.modalRef}>
            <ModalInfoContainer title="Coming soon!" onClose={planIdealistFeatureModal.closeModal}>
              <InfoBox
                fullWidth
                info="You'll be able to plan this list. You'll receive step by step guidance on the day of your trip."
              />
            </ModalInfoContainer>
          </Modal>
          <SimpleButton
            disabled={isCreatedByCurrentUser}
            borderColor={theme.colors.gray300}
            margin="0 10px 10px 0"
            onClick={() => {
              planIdealistFeatureModal.openModal();
            }}
          >
            <Body size="small" margin="0">
              Plan
            </Body>
          </SimpleButton>
        </>
      )}

      {!isCreatedByCurrentUser && (
        <SimpleButton
          disabled={
            isCreatedByCurrentUser || completeIdealistLoading || idealistStats.completedByUser
          }
          backgroundColor={theme.customColors.primary}
          margin="0 0 10px 0"
          onClick={async () => {
            if (actionsDoNothing) {
              return;
            }
            await completeIdealist({ variables: { id: idealistId } });
          }}
        >
          <Body color={theme.colors.white} size="small" margin="0">
            {idealistStats.completedByUser ? 'Already completed' : 'Mark as completed'}
          </Body>
        </SimpleButton>
      )}
    </ActionsDiv>
  );
};

export default IdealistActions;
