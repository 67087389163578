import React from 'react';
import Body from 'components/common/body';
import WithMargin, { WithMarginProps } from 'components/common/margin/withMargin';
import { theme } from 'utils/theme';

type PostInfoProps = {
  children: React.ReactNode;
} & WithMarginProps;

const PostInfo = ({ children, ...rest }: PostInfoProps) => {
  return (
    <WithMargin {...rest}>
      <Body color={theme.colors.gray500}>{children}</Body>
    </WithMargin>
  );
};

export default PostInfo;
