import gql from 'graphql-tag';
import {
  IDALIST_ITEM_CORE_FRAGMENT,
  IDEALIST_CORE_FRAGMENT,
  IDEALIST_LOCATION_CORE_FRAGMENT,
  USER_CORE_FRAGMENT,
} from 'utils/fragments.query';

export const CREATE_DRAFT_IDEALIST = gql`
  mutation CreateDraftIdeaList {
    createDraftIdeaList
  }
`;

export const GET_IDEALIST = gql`
  query GetIdeaList($id: ID!) {
    getIdeaList(id: $id) {
      idealist {
        ...IdealistCore
        items {
          ...IdealistItemCore
        }
        location {
          ...IdealistLocationCore
        }
        creator {
          name
          ...UserCore
        }
        completedBy {
          name
          ...UserCore
        }
        images {
          id
          src
        }
      }
      likeCount
      likedByUser
      completedByUser
    }
  }

  ${USER_CORE_FRAGMENT}
  ${IDEALIST_CORE_FRAGMENT}
  ${IDALIST_ITEM_CORE_FRAGMENT}
  ${IDEALIST_LOCATION_CORE_FRAGMENT}
`;

export const DELETE_IDEALIST = gql`
  mutation DeleteIdealist($id: ID!) {
    deleteIdealist(id: $id)
  }
`;

export const MAKE_IDEALIST_PUBLIC = gql`
  mutation MakeIdealistPublic($id: ID!) {
    makeIdealistPublic(id: $id)
  }
`;
