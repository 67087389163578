import React from 'react';
import Heading from 'components/common/heading';
import WithMargin, { WithMarginProps } from 'components/common/margin/withMargin';
import { theme } from 'utils/theme';

type SubtitleProps = {
  children: React.ReactNode;
} & WithMarginProps;

const Subtitle = ({ children, ...rest }: SubtitleProps) => {
  return (
    <WithMargin {...rest}>
      <Heading level={400} color={theme.colors.gray500}>
        {children}
      </Heading>
    </WithMargin>
  );
};

export default Subtitle;
