import { X } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import EmptyButton from 'components/common/button/empty';
import { theme } from 'utils/theme';

const OuterDiv = styled.div`
  background-color: ${theme.colors.gray50};
  border-radius: 10px;
  padding: 15px 20px;
`;

const CloseModalDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface ModalInfoContainerProps {
  children: React.ReactNode;
  title?: string;
  onClose: () => void;
}

const ModalInfoContainer = ({ children, title, onClose }: ModalInfoContainerProps) => {
  return (
    <OuterDiv>
      <CloseModalDiv>
        <Body margin="0" color={theme.colors.black}>
          {title}
        </Body>
        <EmptyButton margin="0 0 10px 0" onClick={onClose}>
          <X />
        </EmptyButton>
      </CloseModalDiv>
      {children}
    </OuterDiv>
  );
};

export default ModalInfoContainer;
